.swipeable-container {
    position: relative;
    padding: 2rem;
}
.swipeable-container .carousel-nav-button {
    position: absolute;
    top: 50%;
}

.swipeable-container .carousel-nav-button.left {
    left: 0;
}
.swipeable-container .carousel-nav-button.right {
    right: 0;
}

.swipeable-container .carousel-stepper {
    position: absolute;
    bottom: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    width: calc(100% - 3rem);
    justify-content: center;
}

.swipeable-container .carousel-stepper > .carousel-stepper-item {
    height: 8px;
    width: 8px;
    background-color: #ffffff88;
    border-radius: 8px;
    transition: width 0.2s linear;
    cursor: pointer;
}

.swipeable-container .carousel-stepper > .carousel-stepper-item.active {
    width: 1.5rem;
    background-color: #ffffff;
}