.section-to-print {
	display: none;
}

.section-to-print.show {
	display: inherit;
}

@media print {
	body * {
		visibility: hidden;
		height: 0;
	}

	.section-to-print,
	.section-to-print * {
		visibility: visible;
		height: initial;
	}

	.section-to-print {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
	}

	.section-to-print.single-page {
		height: 100%;
		overflow: hidden;
	}

	.section-to-print .p-hide {
		display: none !important;
	}

	.section-to-print .print-preserve-ui .MuiGrid-grid-lg-3 {
		width: 25%;
		flex-basis: 25%;
	}
}

.grid-overflow {
	margin-left: 0.6rem !important;
	overflow: hidden;
	overflow-wrap: anywhere;
}

/**** scrollbar UI changes *****/
/* widtd */
::-webkit-scrollbar {
	width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	outline: unset !important;
	background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #888888;
}

/*** Button classes overrides ****/
.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
	color: #fff !important;
}

.report table {
	width: 96% !important;
	margin: auto !important;
	padding: 0.25rem !important;
	overflow-x: 'auto';
}
.report table thead tr th {
	background-color: #f1f1f1 !important;
}
.report table thead tr th,
.report table tbody tr td {
	padding: 0.5em !important;
}

.plain-table table td {
	border-bottom: unset !important;
}

/****** Disable number input fields spinner *****/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

/******* Side Menu **********/
.MuiCollapse-wrapperInner {
	background-color: #d5e7ff;
}
.MuiCollapse-wrapperInner .fa {
	margin-left: 24px !important;
	font-size: 16px !important;
}

.MuiFormLabel-asterisk,
.MuiInputLabel-asterisk {
	color: red;
}

.track-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1rem;
}

.track-item > label {
	flex-grow: 1;
}

.track-item > div {
	width: 60%;
}

.track-item-div-sm > .track-item > div {
	width: 30%;
}
.track-item-div-md > .track-item > div {
	width: 50%;
}

.mb-3 {
	margin-bottom: 0.75rem !important;
}

.px-3 {
	padding: 0 0.75rem;
}

table.no-wrap td {
	white-space: nowrap;
}

.toolbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 999;
}

.link {
	color: #008ac1;
	text-decoration: underline;
	cursor: pointer;
}

tbody.portable-table-body tr {
	display: flex;
	flex-direction: column;
}
tbody.portable-table-body tr td {
	display: flex;
	flex-direction: row;
	text-align: left;
}

.MuiTableContainer-root.table-sm-padding td.MuiTableCell-sizeSmall {
	padding: 6px;
}

div.clickable-items table td {
	cursor: pointer;
}
