.payment-type-item {
    position: relative;
    border: 2px solid grey;
    border-radius: 6px;
    transition: all 0.2s linear;
    filter: grayscale(1);
}

.payment-type-item.active {
    filter: grayscale(0);
    border-color: #6a009b;
}

.payment-type-item.active::before {
    --border-width: 15px;
    content: "";
    display: block;
    position: absolute;
    width: var(--border-width);
    height: var(--border-width);
    left: 0;
    top: 0;
    border: var(--border-width) solid #6a009b;
    border-right: var(--border-width) solid transparent;
    border-bottom: var(--border-width) solid transparent;
    z-index: 999;
}

.payment-type-item.active::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 12px;
    left: 6px;
    top: 2px;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-radius: 2px;
    transform: rotateZ(45deg);
    z-index: 999;
}
