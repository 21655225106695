.MuiFormControl-root {
	margin-bottom: 1rem !important;
}
.MuiCollapse-wrapperInner {
	padding: 1rem;
}
.bookings-calender > div {
	width: 100%;
}

@media (max-width: 600px) {
	.my-bookings-item-header > .mid-section {
		padding: 0.5rem 1rem;
		width: 100%;
		order: 2;
	}
}
@media (min-width: 600px) {
	.step-description {
		max-width: 355px;
		overflow: scroll;
	}
	.step-description::-webkit-scrollbar {
		display: none;
	}
	.MuiStepContent-root {
		margin-left: 0.8125rem !important;
	}
	.MuiStepConnector-root {
		position: unset !important;
		margin-left: 0.8125rem !important;
	}
	.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
		display: flex !important;
		flex-direction: row !important;
		align-items: end !important;
	}
	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		margin-top: unset !important;
		margin-left: 0.75rem !important;
		text-align: left !important;
	}
}
