.navbar-container {
    background-color: #ee2b3a !important;
    box-shadow: unset !important;
    overflow: hidden;
}

.navbar-container .logo {
    margin: 4px 0;
}

.navbar-container .nav-link {
    color: #ffffff;
    text-decoration: unset;
    font-weight: bold;
    transition: 0.1s color linear, 0.2s padding linear;
}

.navbar-container .nav-link.active {
    --bg-color: white;
    color: var(--bs-navbar-active-color);
    background-color: var(--bg-color);
    height: auto;
    border-radius: 6px;
    position: relative;
    color: #ee2b3a;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
}

.navbar-container .nav-link:not(.active):hover {
    color: #ffffffbf;
}

.links-container {
    transition: max-height 0.3s linear;
}

@media (min-width: 900px) {
    .navbar-container .logo {
        margin: 0;
        margin-bottom: 6px;
    }
    .navbar-container .nav-link.active {
        --bg-color: #ebebeb;
        height: 100%;
        border-radius: 6px 6px 0 0;
        padding: 0 1.5rem;
    }
    .nav-link.active-bg-white.active {
        --bg-color: #ffffff;
    }
    .nav-link.active-bg-sky-blue.active {
        --bg-color: #d5fcff;
    }
    .navbar-container .nav-link.active::before,
    .navbar-container .nav-link.active::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -12px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: transparent;
        box-shadow: 5px 5px 0 var(--bg-color);
    }
    .navbar-container .nav-link.active::after {
        left: unset;
        right: -12px;
        box-shadow: -5px 5px 0 var(--bg-color);
    }
}