body {
	background-color: #ebebeb !important;
	margin: 0px;
}

.main-menu-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #c1c1c1;
	padding: 10px;
	text-align: center;
	height: 125px;
	width: 80%;
	background: #f2f9ff;
	border-radius: 20px;
	margin: 3% !important;
	box-shadow: 2px 2px 10px #cccccc;
	background: linear-gradient(144deg, #f2f9ff, #ffffff, transparent);
}
.menu-menu-link {
	color: #002850;
}
.main-menu-link .fa {
	color: #3499ff;
	font-size: 2.5rem !important;
	margin-bottom: 0.5rem;
}

.main-menu-link.track-awb-no {
	color: #ffffff;
	background: unset !important;
	background-color: #2980b9 !important;
}
.main-menu-link.track-awb-no .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.courier-booking {
	color: #ffffff;
	background: unset !important;
	background-color: #d35400 !important;
}
.main-menu-link.courier-booking .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.parcel-booking {
	color: #ffffff;
	background: unset !important;
	background-color: #16a085 !important;
}
.main-menu-link.parcel-booking .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.manifest {
	color: #ffffff;
	background: unset !important;
	background-color: #f39c12 !important;
}
.main-menu-link.manifest .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.receivings {
	color: #ffffff;
	background: unset !important;
	background-color: #9b59b6 !important;
}
.main-menu-link.receivings .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.rack-position {
	color: #ffffff;
	background: unset !important;
	background-color: #ff7100 !important;
}
.main-menu-link.rack-position .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.delivery {
	color: #ffffff;
	background: unset !important;
	background-color: #27ae60 !important;
}
.main-menu-link.delivery .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.pickup-dilivery {
	color: #ffffff;
	background: unset !important;
	background-color: #97ae27 !important;
}
.main-menu-link.pickup-dilivery .fa.fa-lg {
	color: #ffffff;
}

.main-menu-link.assigned-bookings {
	color: #ffffff;
	background: unset !important;
	background-color: #3296ac !important;
}
.main-menu-link.assigned-bookings .fa.fa-lg {
	color: #ffffff;
}

.BagPopup {
	border: 1px solid #c1c1c1;
	padding: 5px;
	text-align: center;
}
.BagPopup:nth-of-type(1) {
	border-top: 0;
	border-left: 0;
}
.BagPopup:nth-of-type(2) {
	border-top: 0;
}
.BagPopup:nth-of-type(2n) {
	border-right: 0;
}
.BagPopup:last-of-type {
	border-bottom: 0;
}
.BagPopup:nth-last-child(2) {
	border-bottom: 0;
	border-left: 0;
}

.BagCenterContent {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.removeUnderline {
	text-decoration: none;
}
